@use 'variables' as *;

.sm-select {
  appearance: none;
  background: white url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkuNzE3IDMuNzE3YS40LjQgMCAxMS41NjYuNTY2bC00IDRhLjQuNCAwIDAxLS41NjYgMGwtNC00YS40LjQgMCAxMS41NjYtLjU2Nkw2IDcuNDM0bDMuNzE3LTMuNzE3eiIgZmlsbD0iIzY4NzY3RiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) right 12px center no-repeat;
  border: 1px solid $button-border-color;
  border-radius: 4px;
  color: $body-text-color;
  cursor: pointer;
  font-family: $body-font-family; // fallbacks necessary for correct fonts in Firefox options, limited to system-installed
  font-size: rem(14px);
  font-weight: normal;
  padding: 9px 36px 9px 12px;
  -moz-padding-start: 8px; // Firefox puts 4px additional padding on the content
  position: relative;

  > option {
    background-color: white; // Edge inherits from select on :active without this
  }
  &:disabled {
    opacity: .5;
    pointer-events: none;
  }
  &:hover {
    box-shadow: inset 0 0 3px 0 $button-border-color;
  }
  &:active {
    background-color: darken($body-bg-color, 3);
  }
  &:focus {
    outline: 1px dotted rgba(0, 102, 143, .6);
    outline-offset: -2px;
  }
}
