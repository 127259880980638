@use "sass:math";

$body-font-family: 'Open Sans', Tahoma, sans-serif;
$html-font-size: 16px;
@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}
@function rem($px-value) {
  @return #{math.div(strip-unit($px-value), strip-unit($html-font-size))}rem;
}

$border-color: #c1cace;
$body-bg-color: #fff;
$body-text-color: #23282b;
$button-bg-color: #fff;
$button-hover-color: #d7dade;
$button-active-color: #c1cace;
$button-border-color: #c1cace;
$card-bg-color: #fff;
$card-border-color: #c1cace;
$nav-bg-color: rgba(118, 118, 128, .12);
$table-group-header-bg-color: #f3f3f3;
$table-header-border-color: #e2e2e2;
$table-row-border-color: #dedede;
$table-row-light-border-color: #ebebeb;
$table-row-zebra-stripe-bg-color: #f7f7f7;
$text-light-color: #68767f;

$weight-light: 200;
$weight-regular: 400;
$weight-semi-bold: 600;
$weight-bold: 700;
$weight-extra-bold: 800;

$z-sticky-header: 10;
