@use 'variables' as *;

.sm-button {
  background-color: $body-bg-color;
  border: 1px solid $button-border-color;
  border-radius: 4px;
  color: var(--accent-color);
  cursor: pointer;
  font-family: $body-font-family;
  font-size: rem(14px);
  font-weight: 600;
  padding: 8px 16px;
  text-transform: uppercase;
  transition: all .1s 0s;
  &:hover {
    background-color: $button-hover-color;
  }
  &:active {
    background-color: $button-active-color;
    transition-duration: 0s;
  }

  &.highlight {
    background-color: var(--accent-color);
    box-shadow: 0 0 2px rgba(0, 0, 0, .14), inset 0 0 5px 0 var(--accent-color);
    color: white;
    &:hover {
      background-color: color-mix(in srgb, var(--accent-color), #fff 5%);
    }
    &:active {
      background-color: color-mix(in srgb, var(--accent-color), #000 5%);
      transition-duration: 0s;
    }
  }
}

.sm-button--text { // scss-lint:disable QualifyingElement
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  color: var(--accent-color);
  cursor: pointer;
  font-family: inherit;
  font-size: rem(14px);
  font-weight: 600;
  padding: 0 7px;
  text-transform: uppercase;
  &:hover,
  &:active {
    background-color: #f5f9fb;
  }
  &:active {
    background-color: darken(#f5f9fb, 3);
  }
}

.sm-button--img {
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  padding: 6px;
  position: relative;

  &:hover {
    background: color-mix(in srgb, var(--accent-colored-text) 34%, transparent);
  }
  &:hover,
  &:focus {
    [role=tooltip] {
      opacity: 1;
    }
  }
  &:active {
    background: color-mix(in srgb, var(--accent-colored-text) 17%, transparent);
  }
  img {
    display: block; // fixes bottom offset due to descender
  }

  [role=tooltip] {
    background-color: rgba(35, 40, 43, .9);
    border-radius: 4px;
    color: white;
    font-size: rem(14px);
    left: -41px;
    opacity: 0;
    padding: 8px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 51px;
    transition: opacity .1s 0s;
    width: 118px;
  }
}
