@keyframes seSpin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  text-align: center;
  img {
    margin-top: 30vh;
    animation: seSpin .6s linear infinite;
    height: 48px;
    width: 48px;
  }
}
