@use 'variables' as *;
$shared-font-path: "https://s3.amazonaws.com/sportngin-snap-production/ui_themes/assets/latest/fonts";

@mixin font-face($family, $style, $weight, $src) {
  @font-face {
    font-family: $family;
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}
@include font-face('Open Sans', normal, 200, url('#{$shared-font-path}/OpenSans-Light.woff') format('woff'));
@include font-face('Open Sans', normal, 400, url('#{$shared-font-path}/OpenSans-Regular.woff') format('woff'));
@include font-face('Open Sans', normal, 600, url('#{$shared-font-path}/OpenSans-SemiBold.woff') format('woff'));
@include font-face('Open Sans', normal, 700, url('#{$shared-font-path}/OpenSans-Bold.woff') format('woff'));
@include font-face('Open Sans', normal, 800, url('#{$shared-font-path}/OpenSans-ExtraBold.woff') format('woff'));

a:link,
a:visited {
  color: var(--accent-color);
  text-underline-offset: 2px; // scss-lint:disable PropertySpelling
}

// These are used for page-level content headers
.sm-page-title {
  font-size: rem(20px);
  margin: 32px 0 20px;

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

:focus {
  border-radius: 2px; // will be overridden when needed, default to this
  outline: 1px dotted var(--accent-color);
}
