// You can add global styles to this file, and also import other style files

@use 'variables' as *;
@use 'typography';
@use 'select';
@use 'buttons';
@use 'spinner';
@use 'se-fe-fixes';

// scss-lint:disable ImportantRule

* {
  box-sizing: border-box;
}

:root {
  overscroll-behavior-y: none; // disables elastic bounce effect on overscroll
}

html {

  // THEME/SPACING VARIABLES

  --accent-color: #00668f;
  --accent-bg: #1B3348;
  --accent-colored-text: #90cee7;
  --accent-text: #fff;

  --body-padding: 0px;
  --body-padding-embed-phone: 5px;
  --body-padding-phone: 24px;
  --body-padding-desktop: 48px;
  --header-margin-phone: 4px;
  --header-margin-desktop: 32px;
  --footer-margin-phone: 24px;
  --footer-margin-desktop: 32px;
  --max-content-width: 928px;
  --filter-margin-bottom: 0px;
  --filter-margin-top: 0px;
  --filter-margin-phone: 12px;
  --filter-margin-desktop: 24px;

  &.embed {
    --filter-margin: var(--filter-margin-embed);
  }
  @media screen and (max-width: 1023px) {
    --header-margin: var(--header-margin-phone);
    --filter-margin-bottom: var(--filter-margin-phone);

    &.embed {
      --body-padding: var(--body-padding-embed-phone);
    }

    &:not(.embed) {
      --body-padding: var(--body-padding-phone);
      --filter-margin-top: var(--filter-margin-phone);
      --footer-margin: var(--footer-margin-phone);
    }
  }
  @media screen and (min-width: 1024px) {
    --header-margin: var(--header-margin-desktop);
    --filter-margin-bottom: var(--filter-margin-desktop);
    &:not(.embed) {
      --body-padding: var(--body-padding-desktop);
      --filter-margin-top: var(--filter-margin-desktop);
      --footer-margin: var(--footer-margin-desktop);
    }
  }
}

body {
  background-color: $body-bg-color;
  font-family: $body-font-family;
  font-size: rem(16px);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  min-width: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
  text-size-adjust: none;// fixes iOS Safari randomly resizing some texts
}

.hidden,
router-outlet {
  display: none !important;
}

sm-stats-table:not(.hidden) + sm-stats-table {
  margin-top: 0;
}

// SE FE STYLE OVERRIDES

.se-fe-icon--primary {
  color: var(--accent-color) !important;
  fill: var(--accent-color) !important;
}

.se-fe-chip--style-blue-emphasis {
  background: color-mix(in srgb, var(--accent-color) 12%, #fff) !important;
  border-color: var(--accent-color) !important;

  &.se-fe-chip--clickable:hover {
    background: color-mix(in srgb, var(--accent-color) 24%, #fff) !important;
  }

  &.se-fe-chip--clickable:active {
    background: color-mix(in srgb, var(--accent-color) 36%, #fff) !important;
  }
  
  .se-fe-chip__icon-left {
    color: var(--accent-color) !important;
  }
}

.se-fe-chip--style-default.se-fe-chip--clickable,
.se-fe-button--low button {

  &:hover {
    background: color-mix(in srgb, var(--accent-color) 12%, #fff) !important;
  }

  &:active {
    background: color-mix(in srgb, var(--accent-color) 24%, #fff) !important;
  }
}

.se-fe-button--low button {
  color: var(--accent-color) !important;
}

.se-fe-button--high button {
  background: var(--accent-color) !important;
  &:hover {
    background: color-mix(in srgb, var(--accent-color), #fff 8%) !important;
  }
  &:active {
    background: color-mix(in srgb, var(--accent-color), #000 8%) !important;
  }
}

se-fe-button button:focus {
  box-shadow: 0 0 0 calc(0px + 2px) #fff,0 0 0 calc(0px + 4px) var(--accent-color),0 0 0 calc(0px + 6px) #fff !important;
}

.se-fe-form-control-select:focus-within {
  box-shadow: 0 0 0 calc(0px + 2px) #fff,0 0 0 calc(0px + 4px) var(--accent-color),0 0 0 calc(0px + 6px) #fff !important;
}

.se-fe-empty-state__title {
  color: var(--accent-color) !important;
}

.se-fe-avatar__image-persona {
  background-color: var(--accent-bg) !important;
  color: var(--accent-text) !important;
}
